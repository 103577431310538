import React from 'react'
import styled from 'styled-components'

// Components
import Controls from './controls'

export default ({
    title,
    description,
    slideIndex,
    setSlideIndex
}) => {
    return (
        <Header>
            <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Content>
            <Controls
                slideIndex={slideIndex}
                setSlideIndex={setSlideIndex}
            />
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    text-align:left;
    display:flex;
    justify-content:space-between;
    align-items: flex-end;
`
const Content = styled.div`
    max-width:calc(100% - 6.5rem);
`
const Title = styled.h2`
    font-size:0.85rem;
    margin-bottom:0.75rem;
    text-transform:uppercase;
    color:#888894;
    letter-spacing:1px;
    font-weight:400;
`
const Description = styled.p`
    font-size:1.5rem;
    font-weight:bold;
    color: ${props => props.theme.color.blue.dark};
    @media (min-width:1200px) {
        font-size:2rem;
    }
`