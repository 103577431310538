import React from 'react'
import styled from 'styled-components'

// Components
import Author from './author'
import Avatar from './avatar'
import Rating from './rating'

export default ({
    name,
    avatar,
    position
}) => {
    return (
        <Footer>
            <Avatar avatar={avatar} name={name}/>
            <Author name={name} position={position}/>
            <Rating />
        </Footer>
    )
}

// Styled Components
const Footer = styled.footer`
    display:flex;
    align-items: center;
    position:relative;
    flex-wrap:wrap;
    align-content:flex-start;
    margin-top: auto;
`