import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import Content from '../templates/home/index'

export default () => {
    return (
        <Layout>
            <SEO 
                title="Eastern Iowa Residential Remodeling and Construction: JP Home Builders" 
                description="Serving Cedar Rapids and Marion, we are a full service remodeling and construction company. Our team specializes in home, kitchen, bathroom remodeling, and more!"
            />
            <Content/>
        </Layout>
    )
}
