import React from 'react'
import styled from 'styled-components'

// Components
import Author from './author'
import Avatar from './avatar'

export default ({
    name,
    avatar,
    position
}) => {
    return (
        <Footer>
            <Avatar avatar={avatar} name={name}/>
            <Author name={name}/>
        </Footer>
    )
}

// Styled Components
const Footer = styled.footer`
    display:flex;
    align-items: center;
    position:relative;
    justify-content: center;
`