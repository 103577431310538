import React, { useState } from 'react'
import styled from 'styled-components'

// Hooks
import { usePlaceholderBg } from '../../../hooks/placeholders/usePlaceholderBg'

// Components
import Container from '../../../components/container'
import Carousel from '../../../components/carousel/testimonials'
import Header from './header'
import Background from './background'

export default ({
    reviews,
    title,
    description
}) => {
    const [ slideIndex, setSlideIndex ] = useState(0)

    const bg = usePlaceholderBg()
    
    return (
        <Section>
            <Background 
                bg={bg}
            />
            <Container
                sm={`
                    display:grid;
                    grid-template-columns: minmax(0, 1fr);
                    grid-row-gap:1.5rem;
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    grid-row-gap:3rem;
                    padding-top:6rem;
                    padding-bottom:6rem;
                `}
            >
                <Header
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                    title={title}
                    description={description}
                />
                <Carousel
                    reviews={reviews}
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                />
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`

`