import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

export default ({
    avatar,
    name
}) => {
    return (
        <Image fluid={avatar} alt={`Client: ${name}}`}/>
    )
}

// Styled Components
const Image = styled(Img)`
    height:45px;
    width:45px;
    border-radius:50%;
    margin:0 0.75rem 0 0;
    position:relative;
    @media (min-width:1200px) {
        height:60px;
        width:60px;
    }
`