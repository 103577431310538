import { graphql, useStaticQuery } from 'gatsby'

export default () => {
    const { allWordpressWpReview: {
        nodes
    }} = useStaticQuery(graphql`
        query useFeaturedReviews {
            allWordpressWpReview(filter: {acf: {featured: {eq: true}}}) {
                nodes {
                    id
                    acf {
                        title
                        name
                        position
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fixed(width:45, height:45, quality: 70) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return nodes
}