import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default () => {
    return (
        <Header>
            <Title>
                <Subtitle>{`Eastern Iowa's Premier`} </Subtitle>
                <Span>{`Residential Construction`} </Span>
                <Span>{`& Remodeling Solutions`}</Span>
            </Title>
            <Description>{`J.P. Homebuilder's Corp. has been providing Eastern Iowa with top-tier home construction and remodeling services for over 25 years.
            `}</Description>
            <Button to={`/quote/`}>{`Request a Quote`}</Button>
            <Scroll>
                <Icon x="0px" y="0px" width="40.73px" height="110px" viewBox="0 0 40.73 99.511">
                    <g>
                        <path fill="none" stroke="#888894" strokeWidth="2" strokeMiterlimit="10" d="M2.102,45.838c0,9.639,8.361,17.454,18,17.454 s18-7.814,18-17.454V19.452C38.102,9.814,29.74,2,20.102,2s-18,7.814-18,17.452V45.838z"></path>
                        <circle fill="#888894" cx="20.365" cy="20.076" r="3.74"></circle>
                        <polyline fill="none" stroke="#888894" strokeWidth="2" strokeMiterlimit="10" points="39.316,77.73 20.365,96.683 1.414,77.73">
                            <animateMotion dur="1s" values="0,0; 0,5; 0,0" repeatCount="indefinite"></animateMotion>
                        </polyline>
                    </g>
                </Icon>
                {`Scroll Down`}
            </Scroll>
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    position:relative;
    text-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    max-width:375px;
    width:calc(100% - 3rem);
    order:1;
    @media (min-width:1200px) {
        text-shadow:none;
        max-width:60%;
        order:2;
    }
`
const Title = styled.h1`
    color: #fff;
    font-size:1.5rem;
    @media (min-width:1200px) {
        font-size:2.5rem;
        margin:0 0 1.5rem 0;
        letter-spacing:1px;
        color: ${props => props.theme.color.blue.dark};
    }
`
const Subtitle = styled.span`
    color:#fff;
    font-size:0.85rem;
    display:block;
    margin:0 0 0.5rem 0;
    @media (min-width:1200px) {
        color:#888894;
        margin:0;
    }
`
const Span = styled.span`
    @media (min-width:1200px) {
        background-color:#fff;
        margin-top:0.5rem;
        display:inline-block;
        padding:0.25rem 0.25rem 0.25rem 0;
    }
`
const Description = styled.p`
    font-size:0.85rem;
    line-height:1.5;
    display:block;
    padding:0 1rem;
    border-left:2px solid ${props => props.theme.color.blue.primary};
    color:#fff;
    margin:1.5rem 0;
    font-weight:bold;
    @media (min-width:1200px) {
        font-weight:normal;
        margin: 0 0 1.5rem 0;
        border-color:${props => props.theme.color.blue.dark};
        color: ${props => props.theme.color.blue.dark};
        max-width:375px;
    }
    @media (min-width:1920px) {
        margin-bottom:3rem;
    }
`
const Button = styled(Link)`
    color: #fff;
    background-color: ${props => props.theme.color.blue.primary};
    padding:0.5rem 1.5rem;
    display:inline-block;
    text-align:center;
    text-transform:uppercase;
    font-weight:bold;
    letter-spacing:1px;
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    margin: 0 0 1.5rem 0;
    &:hover {
        box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0,0,0,.12)
    }
    @media (min-width:1200px) {
        margin: 0 0 3rem 0;
    }
    @media (min-width:1920px) {
        margin: 0 0 4.5rem 0;
    }
`
const Scroll = styled.span`
    display:none;
    @media (min-width:1200px) {
        display:block;
        color: #888894;
        font-size:0.85rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        display:flex;
        align-items:center;
    }
`
const Icon = styled.svg`
    width: 0.75rem;
    height: 2rem;
    margin-right:1rem;
`