import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import BackgroudImage from 'gatsby-background-image'
import Slider from 'react-slick'

export default ({
    slides,
    speed,
    play,
    togglePlay,
    slideIndex,
    setSlideIndex
}) => {
        
        // Ref: Slider
        const slider = useRef(null)

        // Settings: Slider
        const settings = {
            infinite: true,
            slidesToShow: 1,
            initialSlide: 1,
            slidesToScroll: 1,
            fade:true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: speed,
            speed: 500,
        }
    
        // Controls: Slider
        const goTo = (slide) => {
            slider.current.slickGoTo(slide)
        }
        useEffect(() => {
            if (!play) {
                slider.current.slickPause()
            } else {
                slider.current.slickPlay()
            }
            goTo(slideIndex + 1)
        }, [slideIndex, play])
    return (
        <Wrapper>
            <Carousel ref={slider} {...settings}>
                {slides.map(({
                    featured_media: {
                        localFile: {
                            childImageSharp
                        }
                    }
                }, index) => (
                    <Thumb
                        key={index}
                        fluid={childImageSharp.fluid}
                    />
                ))}
            </Carousel>
            <Overlay onClick={(() => setSlideIndex(slideIndex + 1))}/>
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.div`
    width:calc(100% - 85px);
    height:100%;
    position:relative;
    align-items:center;
    display:flex;
`
const Carousel = styled(Slider)`
    height:100%;
    width:100%;
    div {
        height:100%;
        width:100%;
    }
`
const Thumb = styled(BackgroudImage)`
    width:100%;
    height:100%;
`
const Overlay = styled.button`
    -webkit-appearance:none;
    background-color:rgba(0,0,0,0.25);
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    transition: all .2s;
    cursor:pointer;
    border:none;
    outline:0;
    ${Wrapper}:hover & {
        background-color:rgba(0,0,0,0.55);
    }
`