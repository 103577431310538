import React from 'react'
import styled from 'styled-components'

// Components
import Cta from './cta'

export default ({
    title,
    description,
}) => {
    return (
        <Header>
            <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Content>
            <Cta
                link={`/work/`}
                title={`View Full Portfolio`}
            />
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    align-items:flex-end;
    @media (min-width:1200px) {
        box-shadow: 0px 20px 18px -20px rgba(0,0,0,0.2);
    }
`
const Title = styled.h2`
    font-size:0.85rem;
    margin-bottom:0.75rem;
    text-transform:uppercase;
    color:#888894;
    letter-spacing:1px;
    font-weight:400;
`
const Description = styled.p`
    font-size:1.5rem;
    font-weight:bold;
    line-height: 1;
    color: ${props => props.theme.color.blue.dark};
    @media (min-width: 1200px) {
        font-size:2rem;
        margin-bottom:1.5rem;
    }
`
const Content = styled.div`
    @media (min-width: 1200px) {
        width:50%;
    }
`