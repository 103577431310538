import React from 'react'
import styled from 'styled-components'

// Components

export default ({
    title,
    description
}) => {
    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </React.Fragment>
    )
}

// Styled Components
const Title = styled.h3`
    color: ${props => props.theme.color.blue.dark};
    font-size:1rem;
    font-style:italic;
`
const Description = styled.p`
    font-size:0.85rem;
`