import React from 'react'
import styled from 'styled-components'

export default ({
    name,
    position
}) => {
    return (
        <Author>
            <Name>{name}</Name>
            <Position>{position}</Position>
        </Author>
    )
}

// Styled Components
const Author = styled.div`
    display:grid;
    grid-row-gap:0.5rem;
`
const Position = styled.div`
    font-size:0.75rem;
    display:block;
    line-height:1;
`
const Name = styled.h2`
    line-height:1;
    font-size:0.85rem;
    color: ${props => props.theme.color.blue.dark};
`