import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../../components/container'
import Portfolio from '../../../components/portfolio/index'
import Header from './header'

export default ({
    projects,
    title,
    description
}) => {
    return (
        <Section>
            <Container
                sm={`
                    display:grid;
                    grid-row-gap:1.5rem;
                    padding-top:3rem;
                    padding-bottom:3rem; 
                `}
                xl={`
                    grid-row-gap:3rem;
                    padding-top:6rem;
                    padding-bottom:6rem;
                `}
            >
                <Header
                    title={title}
                    description={description}
                />
                <List>
                    {projects.map(({
                        id,
                        slug,
                        featured_media,
                        acf: {
                            title,
                            description: excerpt
                        }
                    }) => (
                        <Portfolio
                            bg={featured_media.localFile.childImageSharp.fixed}
                            key={id}
                            slug={slug}
                            title={title}
                            description={excerpt}
                        />
                    ))}
                </List>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    background-color:#f5f5fa;
`
const List = styled.div`
    list-style:none;
    margin:0;
    padding:0;
    display:grid;
    grid-template-columns:repeat(2, minmax(0, 1fr));
    grid-column-gap:0.75rem;
    grid-row-gap:0.75rem;
    @media (min-width:992px) {
        grid-column-gap:1.5rem;
        grid-row-gap:1.5rem;
        grid-template-columns:repeat(3, minmax(0, 1fr));
    }
`