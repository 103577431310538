import React from 'react'
import styled from 'styled-components'

// Components
import Thumb from './thumb'

export default ({
    slides,
    speed,
    play,
    togglePlay,
    slideIndex,
    setSlideIndex
}) => {
    return (
        <Wrapper 
            className={`intro__controls`}
            onMouseEnter={() => togglePlay(false)}
            onMouseLeave={() => togglePlay(true)}
        >
            <Thumb
                play={play}
                togglePlay={togglePlay}
                slides={slides}
                speed={speed}
                slideIndex={slideIndex}
                setSlideIndex={setSlideIndex}
            />
            <Direction>
                <Arrow>
                    <Button onClick={() => setSlideIndex(slideIndex - 1)}>
                        <Icon 
                            x="0px" 
                            y="0px"
                            viewBox="0 0 407.436 407.436"
                            className={`invert`}
                        >
                            <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                        </Icon>
                        {`Prev`}
                    </Button>
                </Arrow>
                <Arrow className={`active`}>
                    <Button onClick={() => setSlideIndex(slideIndex + 1)}>
                        {`Next`}
                        <Icon 
                            x="0px" 
                            y="0px"
                            viewBox="0 0 407.436 407.436"
                        >
                            <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                         </Icon>
                    </Button>
                </Arrow>
            </Direction>
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.div`
    height:100px;
    width:calc(100% - 3rem);
    max-width:375px;
    background-color:#fff;
    position:relative;
    display:flex;
    flex: 1 1 0%;
    justify-content:space-between;
    box-shadow:rgba(14,30,37,0.12) 0px 2px 4px 0px;
    overflow:hidden;
    order:2;
    @media (min-width:1200px) {
        margin-bottom:3rem;
        max-width:460px;
        height: 125px;
        order:1;
    }
    @media (min-width:1920px) {
        margin-bottom:4.5rem;
    }
`
const Direction = styled.ul`
    margin:0;
    padding:0;
    list-style:none;
    height:100%;
    width:85px;
`
const Arrow = styled.li`
    width:100%;
    height:50%;
    &:last-child {
        box-shadow:0px -8px 8px -12px rgba(0,0,0,0.15);
    }
`

const Button = styled.button`
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:0.9rem;
    -webkit-appearance:none;
    background-color:transparent;
    border:none;
    outline:0;
    cursor:pointer;
    text-align:center;
    text-transform:uppercase;
    color:rgba(0,0,0,0.35);
    transition: all .2s;
    &:hover, .active & {
        color: ${props => props.theme.color.blue.dark};
    }
`
const Icon = styled.svg`
    height:.8rem;
    fill: rgba(0,0,0,0.35);
    transition: all .2s;
    margin-left:0.25rem;
    &.invert {
        margin-right:0.25rem;
        margin-left:0;
        transform: rotate(180deg);
    }
    ${Button}:hover &, .active & {
        fill: ${props => props.theme.color.blue.dark};
    }
`