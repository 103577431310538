import React from 'react'
import styled from 'styled-components'

// Components
import {
    Gutter,
    Roofing,
    Garage,
    Concrete,
    Fencing,
    Decks,
    Windows,
    Siding
} from './icons'

// Higher Order Component
const Icon = ({
    icon
}) => {
    switch(icon) {
        case `roofing`: 
            return <Roofing/>
        case `gutter`:
            return <Gutter/>
        case `garage`:
            return <Garage/>
        case `concrete`:
            return <Concrete/>
        case `fencing`:
            return <Fencing/>
        case `decks`:
            return <Decks/>
        case `windows`:
            return <Windows/>
        case `siding`:
            return <Siding/>
        default:
            return <Roofing/>
    }
}


export default ({
    icon
}) => {
    return (
        <React.Fragment>
            <Circle>
                <Dot/>
            </Circle>
            <Icon icon={icon}/>
        </React.Fragment>
    )
}

// Styled Components
const Circle = styled.div`
    display:none;
    @media (min-width:992px) {
        height: 34px;
    width: 34px;
    margin:0 auto;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    &:before, &:after {
        transition: transform 0.2s, opacity 0.2s;
        transform: scale(.5);
        opacity: 0;
        margin:0 auto;
        border-radius:100%;
        left:0;
        right:0;
        display:block;
        position:absolute;
        content:'';
    }
    &:before {
        top:0;
        height:34px;
        width:34px;
        box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.15);
    }
    &:after {
        top:7px;
        height:20px;
        width:20px;
        box-shadow: 0px 0px 0px 1px ${props => props.theme.color.blue.primary};
    }
    .active & {
        &:after, &:before {
            opacity:1;
            transform: scale(1);
        }
    }
    }
`
const Dot = styled.div`
    content: '';
    position:absolute;
    top:13px;
    width:8px;
    height:8px;
    left:0;
    right:0;
    margin:0 auto;
    background-color:${props => props.theme.color.blue.primary};
    border-radius:100%;
`