import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../../components/container'
import Item from './item'

export default () => {
    const items = [
        {
            title: 'Reliable',
            description: 'Customer satisfaction is our top priority for each of our clients.'
        }, {
            title: 'Affordable',
            description: 'We offer competitive pricing regardless of your budget.'
        }, {
            title: 'Professional',
            description: 'Our licenced team of professionals provide only top-quality work.'
        }, 
    ]
    return (
        <Section>
            <Container
                sm={`
                    background-color:#fff;
                    box-shadow: 0 4px 11px 0 rgba(37,44,97,.15), 0 1px 3px 0 rgba(93,100,148,.2);
                    padding:1.5rem;
                    margin-top:-3rem;
                    display:grid;
                    grid-template-columns:1fr;
                    grid-row-gap:1.5rem;
                    border-radius: 0.25rem;
                `}
                lg={`
                    grid-template-columns:1.5fr 1fr 1fr 1fr;
                    grid-column-gap:1.5rem;
                    align-items: center;
                    padding:3rem;
                    margin-top:-7rem;
                `}
            >
                <Title>Get the expert help that you <Strong>deserve</Strong>.</Title>
                {items.map(({ title, description }, index) => (
                    <Item
                        key={index}
                        title={title}
                        description={description}
                    />
                ))}
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    position:relative;
    z-index:2;
    overflow: visible;
    &:before {
        content: '';
        background-color:#f5f5fa;
        position:absolute;
        bottom:0;
        top:3rem;
        width:100%;
        z-index:-1;
        @media (min-width:992px) {
            top:7rem;
        }
    }
`
const Title = styled.h2`
    font-size:1.5rem;
    font-weight:normal;
    line-height:1.15;
    text-align:center;
    @media (min-width:992px) {
        font-size:2.25rem;
        text-align:left;
    }
`
const Strong = styled.strong`
    color:${props => props.theme.color.blue.primary};
`