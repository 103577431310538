import React from 'react'
import styled from 'styled-components'

export default ({
    slideIndex,
    setSlideIndex
}) => {
    return (
        <Controls>
            <Item>
                <Button onClick={() => setSlideIndex(slideIndex - 1)}>
                    <Icon 
                        x="0px" 
                        y="0px"
                        viewBox="0 0 407.436 407.436"
                        className="invert"
                    >
                        <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                    </Icon>
                </Button>
            </Item>
            <Item>
                <Button onClick={() => setSlideIndex(slideIndex + 1)}>
                    <Icon 
                        x="0px" 
                        y="0px"
                        viewBox="0 0 407.436 407.436"
                    >
                        <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                    </Icon>
                </Button>
            </Item>
        </Controls>
    )
}

// Styled Components
const Controls = styled.ul`
    margin:0;
    padding:0;
    list-style:none;
    display:grid;
    width:6.5rem;
    height:3rem;
    grid-template-columns:1fr 1fr;
    grid-column-gap:0.5rem;
    @media (min-width:1200px) {
        width:7.5rem;
        height:3.5rem;
    }
`
const Item = styled.li`
    height:100%;
`
const Button = styled.button`
    width:100%;
    height:100%;
    -webkit-appearance:none;
    border:none;
    outline:0;
    background-color:rgba(0,0,0,0.1);
    display:flex;
    align-items:center;
    justify-content:center;
    transition: all .15s;
    cursor:pointer;
    &:hover {
        background-color:${props => props.theme.color.blue.dark};
    }
`
const Icon = styled.svg`
    height:1.2rem;
    fill: ${props => props.theme.color.blue.dark};
    ${Button}:hover & {
        fill:#fff;
    }
    &.invert {
        transform:rotate(180deg)
    }
`