import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

export default ({
    title,
    description
}) => {
    return (
        <Wrapper>
            <Svg viewBox="0 0 27 20">
                <path d="M24.42.45,9,16.35,2.58,9.73A1.5,1.5,0,0,0,.42,11.82l7.5,7.72a1.5,1.5,0,0,0,2.16,0l16.5-17A1.5,1.5,0,0,0,24.42.45Z"></path>
            </Svg>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 3rem auto;
    grid-row-gap:0.75rem;
    grid-column-gap:0.75rem;
    align-self: start;
    @media (min-width:992px) {
        grid-template-columns: 1fr;
    }
`
const Svg = styled.svg`
    background-color:${props => lighten(0.35, props.theme.color.blue.primary)};
    height:3rem;
    width:3rem;
    padding:1rem;
    border-radius:50%;
    fill:${props => props.theme.color.blue.primary};
`
const Title = styled.h3`
    font-weight:normal;
    align-self: center;
    font-size:1.25rem;
    @media (min-width:992px) {
        width:100%;
        font-size:1.5rem
    }
`
const Description = styled.p`
    grid-column: 1 / 3;
    @media (min-width:992px) {
        grid-column: 1;
    }
`