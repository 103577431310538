import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

export default ({
    bg
}) => {
    return (
        <Background fluid={bg}>
            <Overlay/>
        </Background>
    )
}

// Styled Components
const Background = styled(BackgroundImage)`
    position:absolute!important;
    top:0;
    right:0;
    left:0;
    bottom:0;
`
const Overlay = styled.div`
    background: rgb(84, 104, 255);
    z-index:1;
    position:absolute;
    background: linear-gradient(
        90deg, 
        rgba(245,245,250,1) 0%,
        rgba(245,245,250,1) calc(0% + 1.5rem), 
        rgba(255,255,255,0.5) 50%, 
        rgba(245,245,250,1) calc(100% - 1.5rem), 
        rgba(245,245,250,1) 100%)
    ;
    top:0;
    right:0;
    left:0;
    bottom:0;
    @media (min-width:1200px) {
        background: linear-gradient(90deg, rgba(245,245,250,1) 0%, rgba(245,245,250,1) calc(50% - 32.5rem), rgba(255,255,255,0.5) 50%, rgba(245,245,250,1) calc(50% + 32.5rem), rgba(245,245,250,1) 100%);
    }
`