import React from 'react'


// Components
import Intro from './intro/index'
import Proof from './proof'
import FeaturedReviews from './featuredReviews/index'
import Purpose from './purpose/index'
import Services from './services'
import Reviews from './reviews/index'
import Portfolio from './portfolio/index'

// Hooks
import useFeaturedProjects from '../../hooks/projects/useFeaturedProjects'
import useFeaturedReviews from '../../hooks/reviews/useFeaturedReviews'
import useReviews from '../../hooks/reviews/useReviews'
import useRecentProjects from '../../hooks/projects/useRecentProjects'
import useFeaturedServices from '../../hooks/services/useFeaturedServices'

export default ({
    data
}) => {
    return (
        <React.Fragment>
            <Intro 
                slides={useFeaturedProjects()}
            />
            <FeaturedReviews 
                reviews={useFeaturedReviews()}
            />
            <Proof />
            <Purpose />
            <Services 
                services={useFeaturedServices()}
            />
            <Reviews
                title={`Testimonials`}
                description={`A few words from our clients.`}
                reviews={useReviews()}
            />
            <Portfolio
                title={`Our Portfolio`}
                description={`Some of our recent work.`}
                projects={useRecentProjects()}
            />
        </React.Fragment>
    )
}