import React from 'react'
import styled from 'styled-components'

// Components
import Rating from './rating'

export default ({
    name,

}) => {
    return (
        <Author>
            <Name>{name}</Name>
            <Rating/>
        </Author>
    )
}

// Styled Components
const Author = styled.div`
    display:grid;
    grid-row-gap:0.5rem;
    text-align:left;
`
const Name = styled.h2`
    line-height:1;
    font-size:0.85rem;
    color: ${props => props.theme.color.blue.dark};
`