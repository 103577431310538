import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

// Components
import Container from '../../components/container'

export default () => {
    const facts = [
        {
            title: `Trusted by`,
            number: `2,000`,
            label: `customers`
        }, {
            title: `Providing`,
            number: `25 +`,
            label: `years of service`
        }
    ]

    return (
        <Section>
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:6rem;
                    display:grid;
                    grid-template-columns:1fr;
                    grid-row-gap:3rem;
                `}
                lg={`
                    padding-top:6rem;
                    padding-bottom:13rem;
                    grid-row-gap:6rem;
                `}
            >
                <Header>
                    <Title>{`Eastern Iowa's Most Trusted Remodeling Agency`}</Title>
                    <Description>{`J.P. Home Builders Corp. has been trusted by thousands across Eastern with top-tier home construction and remodeling services at afforadble prices.`}</Description>
                </Header>
                <List>
                    {facts.map(({
                        title,
                        number,
                        label
                    }, index) => (
                        <Item key={index}>
                            <ItemTitle>{title}</ItemTitle>
                            <ItemNumber>{number}</ItemNumber>
                            <ItemLabel>{label}</ItemLabel>
                        </Item>
                    ))}
                </List>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    ${props => `
        background-image: radial-gradient( circle at 50% 0,
            ${darken(0.25, props.theme.color.blue.primary)},
            ${darken(0.4, props.theme.color.blue.primary)}
        );
    `}
`
const Header = styled.header`
    text-align:center;
`
const Title = styled.h2`
    color:#fff;
    font-size:1.5rem;
    margin-bottom:1rem;
    @media (min-width:1200px) {
        font-size:2rem;
    }
`
const Description = styled.p`
    color:#fff;
    margin:0 auto;
    max-width:700px;
    line-height:1.75;
    font-size:0.85rem;
    @media (min-width:1200px) {
        font-size:1rem;
    }
`
const List = styled.ul`
    margin:0 auto;
    padding:0;
    list-style:none;
    display:grid;
    grid-template-columns: 1fr 1fr;
    max-width:700px;
    width:100%;
    text-align:center;
`
const Item = styled.li`
    color:#fff;
    line-height:1;
    font-size:0.75rem;
    letter-spacing:1px;
    &:last-child {
        box-shadow: -14px 0px 15px -14px rgba(0,0,0,0.35);
    }
`
const ItemTitle = styled.span`
    display:block;
    text-transform:uppercase;
    font-weight:bold;
`
const ItemLabel = styled.span`
    display:block;
    text-transform:uppercase;
    font-weight:bold;
`
const ItemNumber = styled.span`
    margin:0.25rem 0 0.5rem 0;
    font-size:2.25rem;
    display:block;
    letter-spacing:0px;
    @media (min-width:1200px) {
        font-size:3rem;
        margin:0.25rem 0 1rem 0;
    }
`