import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

export default ({
    avatar,
    name
}) => {
    return (
        <Avatar>
            <Image fixed={avatar} alt={`Client: ${name}}`}/>
        </Avatar>
    )
}

// Styled Components
const Avatar = styled.div`
    position:relative;
    height:45px;
    width:45px;
    margin-right:0.5rem;
    @media (min-width:1200px) {
        margin-right:0.75rem;
    }
`
const Image = styled(Img)`
    height:100%;
    width:100%;
    border-radius:50%;
    margin:0 auto;
`