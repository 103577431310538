import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick'

export default ({
    slides,
    play,
    togglePlay,
    speed,
    slideIndex,
    setSlideIndex
}) => {

    // Ref: Slider
    const slider = useRef(null)

    // Settings: Slider
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        swipe: false,
        fade:true,
        autoplaySpeed: speed,
        speed: 500,
        pauseOnHover: false,
        beforeChange: (current, next) => {
            setSlideIndex(next)
        },
        onInit: () => {
            togglePlay(true)
        }
    }

    // Controls: Slider
    const goTo = (slide) => {
        slider.current.slickGoTo(slide)
    }
    useEffect(() => {
        if (!play) {
            slider.current.slickPause()
        } else {
            slider.current.slickPlay()
        }
        
        goTo(slideIndex)
    }, [slideIndex, play])
    return (
        <Carousel
            ref={slider} 
            {...settings}
        >
            {slides.map(({
                featured_media: {
                    localFile: {
                        childImageSharp
                    }
                }
            }, index) => (
                <Background fluid={childImageSharp.fluid} key={index}/>
            ))}
        </Carousel>
    )
}

// Styled Components
const Carousel = styled(Slider)`
    height:100%;
    width: calc(100% - 1rem);
    position:absolute;
    top:0;
    right:auto;
    left:0;
    overflow:hidden;
    border-radius: 0 0 72px 0;
    border-right:12px solid rgba(0,0,0,0.1);
    border-bottom:12px solid rgba(0,0,0,0.1);
    z-index:-1;
    &:before {
        background-color:rgba(0,0,0,0.45);
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        content: '';
        display:block;
        z-index:1;
    }
    @media (min-width:1200px) {
        &:before {
            display:none;
        }
        width: calc(50% + 210px);
        right:0;
        left:auto;
        border-radius: 0 0 0 72px;
        border-right:none;
        border-left:12px solid rgba(0,0,0,0.1);
    }
`
const Background = styled(BackgroundImage)`
    height:100%;
    width:100%;
`