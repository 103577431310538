import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../../components/container'
import Footer from './footer'

export default ({ reviews }) => {
    return (
        <Section>
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:3rem;
                    display:grid;
                    grid-row-gap:1.5rem;
                    grid-template-columns: repeat(2, 1fr);
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                    grid-column-gap:3rem;
                    grid-template-columns: repeat(4, 1fr);
                `}
            >
                {reviews.map(({
                    id,
                    featured_media,
                    acf: {
                        title,
                        position,
                        name
                    }
                }) => (
                    <Column key={id}>
                        <Title>{`"${title}"`}</Title>
                        <Footer
                            name={name}
                            position={position}
                            avatar={featured_media.localFile.childImageSharp.fixed}
                        />
                    </Column>
                ))}

            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`

`
const Column = styled.div`
    text-align:center;
`
const Title = styled.span`
    font-size:0.85rem;
    display:block;
    line-height:1.5;
    font-weight:bold;
    max-width:215px;
    margin:0 auto 1rem auto;
    color:#888894;
`