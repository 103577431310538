import React from 'react'
import styled from 'styled-components'

export default () => {
    return (
        <Rating x="0px" y="0px" viewBox="204 313.4 980 165.2">
            <g>
                <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                    <path d="M2754.5,1723.2c-70.4-140.8-130.4-256.8-132.5-260.9c-4.1-4.1-130.5-24.9-281.7-47.6c-153.2-22.8-285.8-49.7-298.2-62.1
                        c-14.5-14.5,45.6-87,192.6-229.9L2448,915.6l-47.6-287.9c-24.8-157.4-37.3-294.1-29-302.4c8.3-8.3,134.6,45.6,277.5,120.1
                        l263,136.7l263-136.7c142.9-74.5,269.2-130.5,277.5-120.1c8.3,8.3-6.2,145-33.1,304.4l-47.6,289.9l198.8,188.4
                        c118.1,109.8,200.9,205,200.9,227.8c0,20.7-4.1,39.3-10.4,39.3c-14.5,0-420.4,60.1-503.2,72.5c-64.2,12.4-74.6,26.9-190.5,271.3
                        c-78.8,165.9-132.6,257-153.3,257S2835.3,1884.8,2754.5,1723.2z"/>
                    <path d="M4895.9,1951.1c-12.4-14.5-74.5-130.5-138.8-258.9c-105.6-213.3-120.1-231.9-182.3-244.4c-37.3-6.2-161.5-24.9-275.4-41.4
                        c-113.9-18.6-215.4-41.4-227.8-53.8c-12.4-12.4,53.8-93.2,190.5-231.9l209.2-211.2l-49.7-279.6c-33.1-186.4-43.5-283.7-29-298.2
                        s105.6,22.8,279.6,111.8L4931.1,578L5190,443.4c165.7-84.9,265.1-126.3,277.5-113.9c14.5,14.5,2.1,120.1-29,300.3l-49.7,279.6
                        l211.2,211.2c149.1,153.3,203,219.5,188.5,234c-12.4,12.4-147,41.4-300.3,64.2c-153.3,22.8-279.6,47.6-283.7,55.9
                        c-47.6,113.9-248.5,490.8-265.1,497C4927,1973.8,4906.3,1965.6,4895.9,1951.1z"/>
                    <path d="M6813.7,1723.2c-70.4-140.8-130.5-256.8-132.5-260.9c-4.1-4.1-130.5-24.9-281.7-47.6c-153.3-22.8-285.8-49.7-298.2-62.1
                        c-14.5-14.5,45.6-87,192.6-229.9l215.4-209.2l-47.6-285.8c-26.9-157.4-41.4-294.1-31.1-302.4c8.3-8.3,134.6,45.6,277.5,120.1
                        l263,136.7l263-136.7c142.9-74.5,269.2-130.5,277.5-120.1c8.3,8.3-6.2,145-33.1,304.4l-47.6,289.9l198.8,188.4
                        c118,109.8,200.9,205,200.9,227.8c0,20.7-4.2,39.3-10.4,39.3c-14.5,0-420.4,58-501.2,72.5c-62.1,10.4-74.6,29-190.5,269.2
                        c-80.8,167.8-134.6,258.9-155.3,258.9C6952.4,1975.9,6894.4,1884.8,6813.7,1723.2z"/>
                    <path d="M8899.1,1930.3c-16.6-26.9-74.6-140.8-128.4-254.7c-53.9-113.9-99.4-209.2-101.5-211.2c-2.1-4.1-126.3-22.8-279.6-45.6
                        c-151.2-22.8-285.8-51.8-298.2-64.2c-14.5-14.5,39.4-80.8,188.5-234l211.3-211.2l-51.8-279.6c-33.1-186.4-43.5-283.7-29-298.2
                        S8516,354.4,8690,443.4L8948.9,578l258.9-134.6c174-89.1,265.1-126.3,279.6-111.8s4.2,111.8-29,298.2l-51.8,279.6l211.3,211.2
                        c140.8,142.9,202.9,219.5,188.5,234c-10.4,10.4-145,39.3-298.2,62.1c-161.5,24.9-283.7,53.8-287.9,68.3
                        c-31.1,84.9-244.4,480.5-265.1,486.7C8942.7,1975.9,8917.8,1957.3,8899.1,1930.3z"/>
                    <path d="M10810.7,1717c-116-240.2-128.4-258.9-190.5-269.2c-37.3-6.2-163.6-24.9-283.7-41.4c-120.1-18.6-225.7-41.4-238.2-53.8
                        c-12.4-12.4,51.8-91.1,194.7-229.9l213.3-209.2l-47.6-285.8c-26.9-157.4-41.4-294.1-31.1-302.4c8.3-8.3,134.6,45.6,277.5,120.1
                        l263,136.7l263-136.7c142.9-74.5,269.2-128.4,277.5-120.1c10.4,8.3-4.1,145-31.1,302.4l-47.6,283.7l213.3,211.2
                        c149.1,142.9,209.2,215.4,194.7,229.9c-12.4,12.4-145,39.3-298.2,62.1c-151.2,22.8-279.6,43.5-283.7,47.6
                        c-4.2,4.1-62.1,122.2-130.5,260.9c-82.8,165.7-136.7,252.7-157.4,252.7C10945.3,1975.9,10891.4,1888.9,10810.7,1717z"/>
                </g>
            </g>                
        </Rating>
    )
}

// Styled Components
const Rating = styled.svg`
    display:none;
    @media (min-width:1200px) {
        display:block;
        width:5rem;
        justify-self:end;
        margin-left:auto;
        fill:#fbc501;
    }
`