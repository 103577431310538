export default {
    infinite: true,
    slidesToShow:2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    centerMode: true,
    autoplaySpeed: 3500,
    responsive: [
        {
            breakpoint: 1921,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1621,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1
            }
        },
    ]
}