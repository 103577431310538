import { graphql, useStaticQuery } from 'gatsby'

export default () => {
    const { allWordpressWpService: {
        nodes
    }} = useStaticQuery(graphql`
        query useFeaturedServices {
            allWordpressWpService {
                nodes {
                    slug
                    acf {
                        title
                        excerpt
                        icon
                    }
                }
            }
        }
    `)

    return nodes
}