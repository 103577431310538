import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

// Components
import Container from '../../../components/container'
import Header from './header'
import Controls from './controls'
import Background from './background'

export default ({ slides }) => {
    // Ref
    const wrapper = useRef(null)
    
    // State: Slide Index
    const [ slideIndex, setSlideIndex ] = useState(0)
    const [ play, togglePlay ] = useState(false)

    // Autoplay Speed
    const speed = 5000

    useEffect(() => {
        // Pause if not in viewport
        if (typeof window !== 'undefined') {
            const onScroll = () => {
                const height = wrapper.current.clientHeight
    
                if (play && window.pageYOffset > height - 100) {
                    return togglePlay(false)
                }
    
                return togglePlay(true)
            }

            window.addEventListener('scroll', onScroll)

            return () => {
                window.removeEventListener('scroll', onScroll)
            }
        }
    }, [ play ])

    return (
        <Section ref={wrapper}>
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:3rem;
                    display:grid;
                    grid-template-columns: minmax(0, 1fr);
                    grid-auto-flow:dense;
                `}
                xl={`
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                `}
            >
                <Header/>
                <Controls
                    slides={slides}
                    play={play}
                    togglePlay={togglePlay}
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                    speed={speed}
                />
            </Container>
            <Background
                slides={slides}
                play={play}
                togglePlay={togglePlay}
                speed={speed}
                slideIndex={slideIndex}
                setSlideIndex={setSlideIndex}
            />
        </Section>
    )
}

// Styled Components
const Section = styled.section`

`