import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Components
import Icon from './icon/index'
import Header from './header'
import Cta from './cta'

export default ({
    icon,
    title,
    description,
    link,
    index,
    active,
    setActive
}) => {
    return (
        <Wrapper
            onMouseEnter={(() => setActive(index))}
            className={active === index && `active`}
            to={`/${link}/`}
        >
            <Icon
                icon={icon}
            />
            <Header
                title={title}
                description={description}
            />
            <Cta/>
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled(Link)`
    display:flex;
    flex-wrap:wrap;
    padding:0.5rem;
    height:100%;
    width:100%;
    text-align:center;
    box-shadow:none;
    background-color:rgb(245, 245, 250);
    position: relative;
    z-index: 0;
    align-content: flex-start;
    @media(min-width:992px) {
        flex-direction: column;
        padding:2rem;
        &.active {
            background-color:#fff;
            z-index: 1;
            box-shadow:0 0 38px rgba(0,0,0,.2);
        }
    }
`