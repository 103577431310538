import { graphql, useStaticQuery } from 'gatsby'

export default () => {
    const { allWordpressWpProject: { 
        nodes 
    }} = useStaticQuery(graphql`
        query useFeaturedProjects {
            allWordpressWpProject(limit: 3) {
                nodes {
                    id
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth:1150, maxHeight:805, quality: 65) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }      
    `)

    return nodes
}