import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Components

export default ({
    link,
    title
}) => {
    return (
        <Button to={link}>
            {title}
            <Icon x="0px" y="0px" viewBox="0 0 31.49 31.49">
                <path d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
                C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
                c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
            </Icon>
        </Button>
    )
}

// Styled Components
const Button = styled(Link)`
    display:none;
    @media (min-width:1200px) {
        text-transform:uppercase;
        background-color:transparent;
        outline:0;
        border:none;
        -webkit-appearance:none;
        padding: 0 0.75rem 1.5rem 0.75rem;
        box-shadow:none;
        cursor:pointer;
        transition:all .2s;
        opacity:0.65;
        font-size:0.85rem;
        font-weight:bold;
        color:${props => props.theme.color.blue.dark};
        display:flex;
        align-items: center;
        &:hover {
            opacity:1;
            box-shadow: inset 0px -0.2rem 0px 0px ${props => props.theme.color.blue.primary};
        }
    }
`
const Icon = styled.svg`
    @media (min-width:1200px) {
        height:1rem;
        margin-left:1rem;
        transition: all .2s;
        fill:${props => props.theme.color.blue.dark};
        ${Button}:hover & {
            fill:${props => props.theme.color.blue.primary};
        }
    }
`