import React from 'react'
import styled from 'styled-components'

export default ({
    title,
    description
}) => {
    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </React.Fragment>
    )
}

// Styled Components
const Title = styled.h3`
    color:${props => props.theme.color.blue.dark};
    font-size:1.25rem;
    margin: 0.75rem 0;
    font-weight:normal;
    width:100%;
    line-height:1;
    @media (min-width:992px) {
        margin: 1rem 0 0.5rem 0;
    }
`
const Description = styled.p`
    font-size:0.85rem;
    margin: 0 0 0.75rem 0;
    @media (min-width:992px) {
        margin-bottom:1.5rem;
    }
`