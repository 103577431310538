import React, { useState } from 'react'
import styled from 'styled-components'

// Assets
import logo from '../../images/bbb/logo-color.svg'

// Components
import Container from '../../components/container'
import Service from '../../components/service/index'

export default ({ services }) => {
    const [active, setActive] = useState(0)

    return (
        <Section>
            <Container
                sm={`
                    display:grid;
                    grid-row-gap:1.5rem;
                    grid-template-columns: minmax(0, 1fr);
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    grid-row-gap:3rem;
                    padding-top:6rem;
                    padding-bottom:6rem;
                `}
            >
                <Header>
                    <Content>
                        <Title>{`Our Services`}</Title>
                        <Description>{`Expert remodeling services for all areas of your home.`}
                            <Subtitle>{`Get top quality work regardless of the project scope.`}</Subtitle>
                        </Description>
                    </Content>
                    <Partner>
                        <Background src={logo} alt={`Better Business Bureau Accredited`}/>
                    </Partner>
                </Header>
                <List>
                    {services.map(({
                        slug,
                        acf: {
                            excerpt,
                            title,
                            icon
                        }
                    }, index) => (
                        <Service
                            key={index}
                            title={title}
                            description={excerpt}
                            link={slug}
                            icon={icon}
                            index={index}
                            active={active}
                            setActive={setActive}
                        />
                    ))}
                </List>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    background-color:#f5f5fa;
`
const Header = styled.header`
    text-align:left;
    display:flex;
    justify-content:space-between;
`
const Content = styled.div`
    @media (min-width:992px) {
        max-width:50%;
    }
`
const Title = styled.h2`
    font-size:0.85rem;
    text-transform:uppercase;
    color:#888894;
    letter-spacing:1px;
    font-weight:400;
`
const Description = styled.p`
    font-size:1.5rem;
    font-weight:bold;
    line-height:1.15;
    color: ${props => props.theme.color.blue.dark};
    margin:0.5rem 0 0 0;
    @media (min-width:1200px) {
        font-size:2rem;
    }
`
const Subtitle = styled.span`
    display:block;
    font-size:0.85rem;
    opacity:0.75;
    font-weight:normal;
    margin:0.5rem 0 0 0;
`
const Partner = styled.div`
    display:none;
    @media (min-width:992px) {
        width:300px;
        background-color:rgba(0,0,0,0.05);
        display:flex;
        align-items:center;
    }
`
const Background = styled.img`
    @media (min-width:992px) {
        width:192px;
        max-height:calc(100% - 1rem);
        margin:0 auto;
    }
`
const List = styled.div`
    list-style:none;
    margin:0;
    padding:0;
    display:grid;
    background-color:rgba(0,0,0,0.1);
    grid-row-gap:1px;
    grid-column-gap:1px;
    grid-template-columns:repeat(2, minmax(0, 1fr));
    width:100%;
    grid-auto-flow:dense;
    @media (min-width:992px) {
        grid-template-columns:repeat(4, minmax(0, 1fr));
    }
`