import React, { useRef, useEffect } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

// Utils
import defaultSettings from './settings'

// Components
import Header from './header'
import Footer from './footer'

export default ({
    slideIndex,
    setSlideIndex,
    reviews
}) => {
    const settings = {
        ...defaultSettings,
        beforeChange: (current, next) => {
            setSlideIndex(next)
        }
    }
    
    // Refs
    const slider = useRef(null)

    // Slider Functions
    const goTo = (slide) => {
        slider.current.slickGoTo(slide)
    }
    useEffect(() => {
        goTo(slideIndex)
    }, [slideIndex])
    return (
        <Carousel ref={slider} {...settings}>
            {reviews.map(({
                id,
                featured_media,
                acf: {
                    title,
                    name,
                    position,
                    description
                }
            }) => (
                <React.Fragment key={id}>
                    <Card>
                        <Header
                            title={title}
                            description={description}
                        />
                        <Footer
                            avatar={featured_media.localFile.childImageSharp.fluid}
                            name={name}
                            position={position}
                        />
                    </Card>
                </React.Fragment>
            ))}
        </Carousel>
    )
}

// Styled Components
const Carousel = styled(Slider)`
    width:100%;
    text-align:left;
    position:relative;
    &:before, &:after {
        content: '';
        display:block;
        position:absolute;
        top:0;
        bottom:0;
        width:30px;
        z-index:2;
    }
    &:after {
        right:0;
        box-shadow: inset -30px 0px 12px -20px rgba(245,245,250,1);
    }
    &:before {
        left:0;
        box-shadow: inset 30px 0px 12px -20px rgba(245,245,250,1);
    }
`
const Card = styled.figure`
    background-color: #fff;
    display:grid;
    grid-template-columns:minmax(0, 1fr);
    grid-row-gap:0.5rem;
    padding:0.75rem;
    border-radius:0;
    box-shadow:0 6px 25px -8px rgba(0,0,0,.20);
    height:100%;
    align-items:start;
    max-width: calc(100% - 1rem);
    @media (min-width:1200px) {
        padding:1.5rem;
        max-width: calc(100% - 1.5rem);
    }
`